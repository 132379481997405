const { now } = require("lodash");

window.clearList = (ulList) => {
    let child = ulList.lastElementChild; 
    while (child) {
        ulList.removeChild(child);
        child = ulList.lastElementChild;
    }
}

window.addToList = (ulList, text) => {
    let li = document.createElement("li");
    li.appendChild(document.createTextNode(text));
    ulList.appendChild(li);
}
window.addToList = (ulList, text, classes, tooltip = "") => {
    let li = document.createElement("li");
    li.appendChild(document.createTextNode(text));
    li.setAttribute("class", classes);

    if(tooltip) {
        li.setAttribute("data-bs-toggle", "tooltip");
        li.setAttribute("data-bs-placement", "right");
        li.setAttribute("title", tooltip);

        let i = document.createElement("i");
        i.setAttribute("class", "bi bi-exclamation-circle float-end");
        li.appendChild(i);
    }

    ulList.appendChild(li);
}

window.detectMobile = () => {
    return navigator.userAgent.match(/android|iphone/i);
}

window.checkEmailAddress = (element) => {
    let regexEmail = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'g');
    let providedEmail = element.value;
    
    if(regexEmail.test(providedEmail)) {
        element.classList.add('is-valid');
        element.classList.remove('is-invalid');
    } else {
        element.classList.add('is-invalid');
        element.classList.remove('is-valid');
    }
}

window.checkIfDateIsInThePast = (date) => {
    givenDate = new Date(date);
    currentDate = new Date();
    let timeDiff = givenDate - currentDate;
    
    if(timeDiff < 0)
        return true;

    return false;
}