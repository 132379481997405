window.Popper = require('@popperjs/core');

import './bootstrap';
import './helpers'

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import * as Calendar from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import nlLocale from '@fullcalendar/core/locales/nl';

window.FullCalendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.listPlugin = listPlugin;
window.interactionPlugin = interactionPlugin;
window.nlLocale = nlLocale;